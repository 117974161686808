import React from "react"

import Layout from '../components/Layout'
import { Helmet } from "react-helmet"
import { Link } from "gatsby"



const CMSPage = props => {
  const { pageContext } = props

  return (   

            <Layout page="careers">
                <Helmet>
                    <body className="careers-body" />
                    <meta charSet="utf-8" />
                    <meta name="description" content="Cancellation & Refund Policy of The Able Guys" /> 
                    <title>Cancellation & Refund Policy</title>
                </Helmet>

                <div className="consistent-gap">
                    <div className="text-center">
                            <h1 className="heading-careers mx-4">Cancellation & Refund Policy</h1>
                    </div>
                </div>

                <div className="container-fluid purple-bg">

          </div>


          <div className="container">

                <div id="description" className="content">
                 
                    <div id="sauce" className="about-panel">
                    <p>Last updated on 13-03-2025 11:50:27</p>
                    <p>
                        NEXTAG CONSULTANCY SERVICES LLP doesn't do refund of orders as its service based company and not a product based company. In case your service is not rendered you can cancel that order and reddem your amount in future by availing any of our services.
                    </p>

                    <h5 class="mt-4">Questions</h5>
                    <p class="pb-2">
                        If you have any questions about our Cancellation & Refund Policy, please <Link to="/contact">contact us</Link>.
                    </p>


                    </div>
                </div>
              
            </div>
        
        </Layout>

  )
}
export default CMSPage